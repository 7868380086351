nav{
    background-color: black;
}

a{
    color: aliceblue;
    font-size: 0.8 em;
    text-decoration: none;
    padding-right: 30px;
}

body {
    margin: 0;
}

nav{
    display: flex;
    justify-content: space-between;
    padding: 6px 16px;
}

.active{
    color: goldenrod;
}

.lista{
   display: grid;
   gap: 5px;
   grid-template-columns: repeat(auto-fit,minmax(280px, 1fr));
}

.destino{
    border: 1px solid gray;
    padding: 8px 12px 10px;
    box-sizing: border-box;
    border-radius: 5px;
}

img{
    width: 100%;
    vertical-align: top;
}

.nombre{
    font-size: 0.92em;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.situacion{
    margin: 5px 0px 10px;
    font-size: 0.74em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.destino a, .aviso a{
    background-color: green;
    color: aliceblue;
    text-align: center;
    padding: 2px 12px 4px;
    border-radius: 3px;
    display: inline-block;
    margin-top: 5px;
}

.imagenGrande{
    width: 500px;
    margin: auto;
}
.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}
.circulo{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: green;
    display: inline-block;
    text-align: center;
    color: aliceblue;
}

.boton{
    font-size: 0.9em;
    background-color: orangered;
    color: aliceblue;
    border: none;
    border-radius: 4px;
    padding: 3px 9px;
    cursor: pointer;
}

.contratar{
    background-color: green;
}

.anular{
    background-color: red;
}

.contratar .anular{
    position: absolute;
    top: 10px;
    right: 80px;
}

.anularRuta{
    margin: 5px 16px 3px;
}

.login{
    padding-top: 20px;
    width: 520px;
    height: 180px;
    border: 6px solid orangered;
    border-radius: 12px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: orangered;
}

input{
    color: orangered;
    padding: 5px 9px;
    font-size: 1.1em;
    outline: none;
    border: 1px dotted orangered;
    border-radius: 5px 0 0 5px;
    margin-left: 15px;
    border-right: none;
}

.botonRegistro{
    background-color: orangered;
    color: aliceblue;
    font-size: 1.1em;
    padding: 4px 10px;
    border: 1px solid orangered;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}